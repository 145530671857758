import { dashboardName } from "@/helper/constant.js";

let NavList = {
  items: [
    {
      name: "Wanasa Dashboard",
      url: "/dashboard",
      icon: "icon-home",
    },
    {
      name: "BannersV2",
      url: "/banners",
      icon: "icon-diamond",
      children: [
        {
          name: "Banner List V2",
          url: "/banners/bannerlist-v2",
          icon: "fa fa-file-photo-o",
        },
        {
          name: "Add Movie Banner V2",
          url: "/banners/addbanner-v2",
          icon: "fa fa-file-photo-o",
        },
        {
          name: "Add Static Banner V2",
          url: "/banners/addstaticbanner-v2",
          icon: "fa fa-file-photo-o",
        },
      ],
    },
    // {
    //   name: "Banners",
    //   url: "/banners",
    //   icon: "icon-diamond",
    //   children: [
    //     {
    //       name: "Banner List",
    //       url: "/banners/bannerlist",
    //       icon: "fa fa-file-photo-o",
    //     },
    //     {
    //       name: "Add Movie Banner",
    //       url: "/banners/addbanner",
    //       icon: "fa fa-file-photo-o",
    //     },
    //     {
    //       name: "Add Static Banner",
    //       url: "/banners/addstaticbanner",
    //       icon: "fa fa-file-photo-o",
    //     },
    //   ],
    // },

    {
      name: "User Management",
      url: "/user-management",
      icon: "fa fa-users",
      children: [
        {
          name: "Add User",
          url: "/user-management/add-user",
          icon: "fa fa-user-plus",
        },
        {
          name: "Dashboard Users",
          url: "/user-management/dash-users",
          icon: "fa fa-list",
        },
        {
          name: "Application Users",
          url: "/user-management/app-users",
          icon: "fa fa-list",
        },
        {
          name: "User Offer",
          url: "/user-management/user-offer",
          icon: "fa fa-ticket",
        },
      ],
    },
    {
      name: "Now Showing Movies",
      url: "/user-management",
      icon: "fa fa-users",
      children: [
        {
          name: "List Now Showing Movies",
          url: "/now-showing/list",
          icon: "fa fa-user-plus",
        },
      ],
    },

    {
      name: "Movies",
      url: "/movies",
      icon: "fa fa-ticket",
      children: [
        {
          name: "Add Movie",
          url: "/movies/add-movie",
          icon: "icon-speech",
        },
        {
          name: "Add Voucher",
          url: "/movies/addvoucher",
          icon: "fa fa-file-photo-o",
        },
        {
          name: "List of Voucher",
          url: "/movies/voucherlist",
          icon: "fa fa-file-photo-o",
        },
        {
          name: "List of Movies",
          url: "/movies/all-movies",
          icon: "fa fa-list",
        },
        {
          name: "Link Movies",
          url: "/movies/link-movies",
          icon: "fa fa-list",
        },
        // {
        //   name: "Add Coming Soon",
        //   url: "/movies/add-coming-soon",
        //   icon: "fa fa-list",
        // },
        // {
        //   name: "Add Movie Critics",
        //   url: "/movies/add-critic-rating",
        //   icon: "fa fa-list",
        // },
        // {
        //   name: "List Movie Critics",
        //   url: "/movies/list-critic-rating",
        //   icon: "fa fa-list",
        // },
        // {
        //   name: 'Map Movies',
        //   url: '/movies/map-movies',
        //   icon: 'fa fa-list',
        // },
      ],
    },

    {
      name: "Exhibitor Management",
      url: "/org-management",
      icon: "fa fa-building",
      children: [
        {
          name: "Organization",
          url: "/org-management/organization",
          icon: "fa fa-building",
          children: [
            {
              name: "Add Organization",
              url: "/org-management/organization/add-org",
              icon: "icon-speech",
            },
            {
              name: "List of Organizations",
              url: "/org-management/organization/all-orgs",
              icon: "icon-speech",
            },
          ],
        },
        // {
        //   name: "Schedule Details",
        //   url: "/org-management/schedule-details",
        //   icon: "icon-film",
        //   children: [
        //     {
        //       name: "Show Schedule Report",
        //       url: "/org-management/schedule/schedule-details",
        //       icon: "fa fa-list",
        //     },
        //   ],
        // },
        {
          name: "Cinema Site V2",
          url: "/org-management/cinemas",
          icon: "icon-film",
          children: [
            {
              name: "Add Cinemas V2",
              url: "/org-management/cinemas/add-cinema-v2",
              icon: "fa fa-list",
            },
            {
              name: "List of Cinemas V2",
              url: "/org-management/cinemas/all-cinemas-v2",
              icon: "fa fa-list",
            },
          ],
        },
        {
          name: "Cinema Site",
          url: "/org-management/cinemas",
          icon: "icon-film",
          children: [
            {
              name: "Add Cinema",
              url: "/org-management/cinemas/add-cinema",
              icon: "icon-speech",
            },
            {
              name: "Add Cinema Charges",
              url: "/org-management/cinemas/add-cinema-charges",
              icon: "icon-speech",
            },
            {
              name: "List of Cinemas",
              url: "/org-management/cinemas/all-cinemas",
              icon: "fa fa-list",
            },
          ],
        },
        {
          name: "Screens",
          url: "/org-management/screens",
          icon: "fa fa-desktop",
          children: [
            {
              name: "Add Screen",
              url: "/org-management/screens/add-screen",
              icon: "icon-speech",
            },
            {
              name: "Screen Seat Layout",
              url: "/org-management/screens/add-screen-seatlayout",
              icon: "icon-speech",
            },
            {
              name: "List of Screens",
              url: "/org-management/screens/all-screens",
              icon: "fa fa-list",
            },
            {
              name: "Compare Screen",
              url: "/org-management/screens/compare-screen",
              icon: "icon-speech",
            },
          ],
        },
      ],
    },
    // {
    //   name: "Manual Bookings",
    //   url: "/manual-bookings",
    //   icon: "fa fa-list",
    // },
    {
      name: "View/Print Ticket",
      url: "/view-print-ticket",
      icon: "fa fa-list",
    },
    {
      name: "Transaction Log",
      url: "/transaction-log",
      icon: "fa fa-list",
    },
    // {
    //   name: "Schedules Check",
    //   url: "/CinemaMovieCountsaspx",
    //   icon: "fa fa-list",
    // },
    {
      name: "Schedules",
      url: "/schedules",
      icon: "fa fa-list",
      children: [
        {
          name: "Schedules Check",
          url: "/schedules/CinemaMovieCountsaspx",
          icon: "fa fa-list",
        },
        {
          name: "Manual Schedule Sync",
          url: "/schedules/scheduleForm",
          icon: "fa fa-list",
        },
      ],
    },
    {
      name: "Censor Ratings",
      url: "/ratings",
      icon: "fa fa-list",
      children: [
        {
          name: "Censor Ratings List",
          url: "/ratings/censor-ratings",
          icon: "fa fa-list",
        },
      ],
    },
    // {
    //   name: "ReportsV2",
    //   url: "/reports",
    //   icon: "fa fa-list",
    //   children: [
    //     // {
    //     //   name: "Show Date Report V2",
    //     //   url: "/reports/show-date-v2",
    //     //   icon: "fa fa-list",
    //     // },
    //     // {
    //     //   name: "Transcation Report V2",
    //     //   url: "/reports/transaction-date-v2",
    //     //   icon: "fa fa-list",
    //     // },
    //     {
    //       name: "Accounts",
    //       url: "/reports/accounts/accounts-report",
    //       icon: "fa fa-list",
    //       children: [
    //         {
    //           name: "Details Report",
    //           url: "/reports/accounts/details-report",
    //           icon: "fa fa-list",
    //         },
    //         {
    //           name: "Internal Report",
    //           url: "/reports/accounts/accounts-report",
    //           icon: "fa fa-list",
    //         },
    //         {
    //           name: "Detail Accounts Report",
    //           url: "/reports/accounts/detail-accounts-report",
    //           icon: "fa fa-list",
    //         },
    //         {
    //           name: "Booking Details Report",
    //           url: "/reports/accounts/booking-details-report",
    //           icon: "fa fa-list",
    //         },
    //         {
    //           name: "Internal Accounts Report",
    //           url: "/reports/accounts/internal-accounts-report",
    //           icon: "fa fa-list",
    //         },
    //       ],
    //     },
    //   ],
    // },
    {
      name: "Accounts ReportsV2",
      url: "/reports",
      icon: "fa fa-list",
      children: [
        {
          name: "Cineco Detail Report",
          url: "/reports/accounts/details-report",
          icon: "fa fa-list",
        },
        {
          name: "Cineco Accounts Report",
          url: "/reports/accounts/accounts-report",
          icon: "fa fa-list",
        },
        {
          name: "Detail Accounts Report",
          url: "/reports/accounts/detail-accounts-report",
          icon: "fa fa-list",
        },
        {
          name: "Booking Details Report",
          url: "/reports/accounts/booking-details-report",
          icon: "fa fa-list",
        },
        {
          name: "Internal Accounts Report",
          url: "/reports/accounts/internal-accounts-report",
          icon: "fa fa-list",
        },

        {
          name: "Novo Accounting Report",
          url: "/reports/accounts/novo-accounts-report",
          icon: "fa fa-list",
        },
        {
          name: "Mukta Accounting Report",
          url: "/reports/accounts/mukta-accounts-report",
          icon: "fa fa-list",
        },
      ],
    },
    // {
    //   name: "Event Master",
    //   url: "/event-master",
    //   icon: "fa fa-list",
    //   children: [
    //     {
    //       name: "List Of Events",
    //       url: "/event-master/listOfevents",
    //       icon: "fa fa-list",
    //     },
    //     {
    //       name: "Create Event",
    //       url: "/event-master/createevent",
    //       icon: "fa fa-list",
    //       children: [
    //         {
    //           name: "Event Details",
    //           url: "/event-master/listOfevents",
    //           icon: "fa fa-list",
    //         },
    //         {
    //           name: "Organiser Details",
    //           url: "/event-master/organiserdetails",
    //           icon: "fa fa-list",
    //         },
    //         {
    //           name: "Additional Details",
    //           url: "/src/Component/Event Master/ListOfEvents",
    //           icon: "fa fa-list",
    //         },
    //         {
    //           name: "Add More Fields",
    //           url: "/src/Component/Event Master/ListOfEvents",
    //           icon: "fa fa-list",
    //         },
    //       ],
    //     },
    //     {
    //       name: "Manage Event",
    //       url: "/src/Component/Event Master/ManageEvent",
    //       icon: "fa fa-list",
    //     },
    //     {
    //       name: "Report",
    //       url: "/src/Component/Event Master/Report",
    //       icon: "fa fa-list",
    //     },
    //   ],
    // },
    // {
    //   name: "Reports",
    //   url: "/reports",
    //   icon: "fa fa-list",
    //   children: [
    //     {
    //       name: "Show Date Report",
    //       url: "/reports/show-date",
    //       icon: "fa fa-list",
    //     },
    //     {
    //       name: "Transcation Report",
    //       url: "/reports/transaction-date",
    //       icon: "fa fa-list",
    //     },
    //     {
    //       name: "Purchase Voucher Report",
    //       url: "/reports/purchase-voucher",
    //       icon: "fa fa-list",
    //     },
    //     {
    //       name: "Reconciliation Reports",
    //       url: "/reports/reconciliation",
    //       icon: "fa fa-list",
    //     },
    //     {
    //       name: "Compare Transactions",
    //       url: "/reports/comparetransactions",
    //       icon: "fa fa-list",
    //     },
    //     {
    //       name: "Compare Settlement Transactions",
    //       url: "/reports/comparesettlementtransaction",
    //       icon: "fa fa-list",
    //     },

    //     // {
    //     //   name: 'Transaction Date Report',
    //     //   url: '/reports/transaction-date',
    //     //   icon: 'fa fa-list'
    //     // }
    //   ],
    // },

    /**
     *
     */

    // {
    //   name: 'Platform Configuration',
    //   url: '/masters',
    //   icon: 'fa fa-sitemap',
    //   children: [
    //     {
    //       name: 'Countries',
    //       url: '/masters/countries',
    //       icon: 'fa fa-globe',
    //     },
    //     {
    //       name: 'States',
    //       url: '/masters/states',
    //       icon: 'fa fa-arrows-alt',
    //     },
    //     {
    //       name: 'Cities',
    //       url: '/masters/cities',
    //       icon: 'fa fa-map-marker',
    //     },
    //     {
    //       name: 'Areas',
    //       url: '/masters/areas',
    //       icon: 'fa fa-location-arrow',
    //     },
    //     {
    //       name: 'Media',
    //       url: '/masters/media',
    //       icon: 'fa fa-file-video-o',
    //     },
    //     // {
    //     //   name: 'Movies',
    //     //   url: '/movies',
    //     //   icon: 'fa fa-ticket',
    //     //   children: [
    //     //     {
    //     //       name: 'Add Movie',
    //     //       url: '/masters/movies/add-movie',
    //     //       icon: 'icon-speech',
    //     //     },
    //     //     {
    //     //       name: 'List of Movies',
    //     //       url: '/masters/movies/all-movies',
    //     //       icon: 'fa fa-list',
    //     //     },
    //     //     {
    //     //       name: 'Map Movie',
    //     //       url: '/masters/movies/map-movies',
    //     //       icon: 'fa fa-list',
    //     //     },
    //     //   ]
    //     // },
    //     {
    //       name: 'Language',
    //       url: '/masters/language',
    //       icon: 'fa fa-language',
    //     },
    //     {
    //       name: 'Genre',
    //       url: '/masters/genre',
    //       icon: 'fa fa-users',
    //     },
    //     {
    //       name: 'Info Type',
    //       url: '/masters/info-type',
    //       icon: 'fa fa-info',
    //     },
    //     {
    //       name: 'Show Category',
    //       url: '/masters/show-category',
    //       icon: 'fa fa-file-movie-o',
    //     },
    //     // {
    //     //   name: 'Template Type',
    //     //   url: '/masters/add-template-type',
    //     //   icon: 'icon-speech'
    //     // },
    //     {
    //       name: 'Template',
    //       url: '/masters/template',
    //       icon: 'icon-speech'
    //     },
    //   ]
    // },

    // {
    //   name: 'Notification',
    //   url: '/notifications',
    //   icon: 'fa fa-paper-plane-o',
    //   children: [{
    //     name: 'Send Notification',
    //     url: '/notifications/send-notification',
    //     icon: 'fa fa-comment-o'
    //   }]
    // },
    // {
    //   name: 'Third-Party',
    //   url: '/third-party',
    //   icon: 'fa fa-external-link',
    //   children: [
    //     {
    //       name: 'Showbizz',
    //       url: '/third-party/showbizz',
    //       icon: 'fa fa-comment-o'
    //     },
    //     {
    //       name: 'Vista',
    //       url: '/third-party/vista',
    //       icon: 'fa fa-comment-o'
    //     },
    //     {
    //       name: 'Manual Sync',
    //       url: '/third-party/manual-sync',
    //       icon: 'fa fa-comment-o'
    //     },
    //   ]
    // },
    // {
    //   name: 'Programming',
    //   url: '/programming',
    //   icon: 'icon-speech',
    //   children: [
    //     {
    //       name: 'Create Programming',
    //       url: '/programming/add-programming',
    //       icon: 'icon-speech'
    //     },
    //     {
    //       name: 'List of Programming',
    //       url: '/programming/all-programming',
    //       icon: 'icon-speech'
    //     },
    //     // {
    //     //   name: 'Send Confirmation',
    //     //   url: '/programming/send-confirmation',
    //     //   icon: 'fa fa-paper-plane-o'
    //     // },
    //     // {
    //     //   name: 'Custom Screening',
    //     //   url: '/screenings',
    //     //   icon: 'fa fa-file-movie-o',
    //     //   children: [
    //     //     {
    //     //       name: 'Add Screening',
    //     //       url: '/programming/screenings/add-screening',
    //     //       icon: 'icon-speech',
    //     //     },
    //     //     {
    //     //       name: 'List of Screenings',
    //     //       url: '/programming/screenings/all-screenings',
    //     //       icon: 'fa fa-list',
    //     //     },
    //     //   ]
    //     // },
    //   ]
    // },
    // {
    //   name: 'Content',
    //   url: '/content',
    //   icon: 'fa fa-file-code-o',
    //   children: [
    //     {
    //       name: 'Add Content',
    //       url: '/content/add-content',
    //       icon: 'icon-speech'
    //     },
    //     // {
    //     //   name: 'Terms & Conditions1',
    //     //   url: '/content/terms-and-conditions1',
    //     //   icon: 'fa fa-book'
    //     // },
    //     // {
    //     //   name: 'Terms & Conditions2',
    //     //   url: '/content/terms-and-conditions2',
    //     //   icon: 'fa fa-book'
    //     // }
    //   ]
    // },
    // {
    //   name: 'Finance',
    //   url: '/admin-finance',
    //   icon: 'fa fa-list',
    //   children: [
    //     {
    //       name: 'Finance Report',
    //       url: '/admin-finance/report',
    //       icon: 'fa fa-user-plus',
    //     },
    //     {
    //       name: 'Add Transfer',
    //       url: '/admin-finance/add-transfer',
    //       icon: 'fa fa-list',
    //     }
    //   ]
    // },

    // {
    //   name: 'Coupon Code',
    //   url: '/coupon-code',
    //   icon: 'fa fa-ticket'
    // },
    {
      name: "Cineco Show Details",
      url: "/cinecoshow",
      icon: "fa fa-list",
      children: [
        {
          name: "Details",
          url: "/cinecoshow/cinecoshowdetails",
          icon: "fa fa-list",
        },
      ],
    },
    {
      name: "Event Info",
      url: "/eventinfo",
      icon: "fa fa-list",
      children: [
        {
          name: "Events",
          url: "/eventinfo/addeventinfo",
          icon: "fa fa-list",
        },
        {
          name: "View Events",
          url: "/eventinfo/vieweventinfo",
          icon: "fa fa-list",
        },
        {
          name: "Block Seats",
          url: "/eventinfo/blockseat",
          icon: "fa fa-list",
        },
        {
          name: "Seat Layout",
          url: "/eventinfo/seat-layout",
          icon: "fa fa-list",
        },
      ],
    },
    // {
    //   name: "Vendor Master",
    //   url: "/vendor-master",
    //   icon: "fa fa-ticket",
    // },
    {
      name: "Events",
      url: "/event",
      icon: "icon-diamond",
      children: [
        {
          name: "Event Category",
          url: "/events/eventlist",
          icon: "fa fa-file-photo-o",
          children: [
            {
              name: "List Event Category",
              url: "/events/eventlist",
              icon: "fa fa-file-photo-o",
            },
            {
              name: "Add Event Category",
              url: "/events/addevent",
              icon: "fa fa-file-photo-o",
            },
          ],
        },
        {
          name: "Event Manager",
          url: "/events/eventmanagerlist",
          icon: "fa fa-file-photo-o",
          children: [
            {
              name: "List Event Manager",
              url: "/events/eventmanagerlist",
              icon: "fa fa-file-photo-o",
            },
            {
              name: "Add Event Mananger",
              url: "/events/addeventmanager",
              icon: "fa fa-file-photo-o",
            },
          ],
        },
        {
          name: "Event Banner",
          url: "/events/eventbannerlist",
          icon: "fa fa-file-photo-o",
          children: [
            {
              name: "List Event Banner",
              url: "/events/eventbannerlist",
              icon: "fa fa-file-photo-o",
            },
            {
              name: "Add Event Banner",
              url: "/events/addeventbanner",
              icon: "fa fa-file-photo-o",
            },
          ],
        },
      ],
    },
    {
      name: "Events Report",
      url: "/reports-data",
      icon: "fa fa-list",
      children: [
        {
          name: "Reports",
          url: "/reports-data/view-reports",
          icon: "fa fa-list",
        },
      ],
    },
    // // {
    // //   name: 'Admin Finance Report',
    // //   url: '/admin-finance-report',
    // //   icon: 'fa fa-list',
    // // },

    // {
    //   name: 'Cinema Finance Report',
    //   url: '/cin-finance-report',
    //   icon: 'fa fa-list',
    // },
    // {
    //   name: 'Organization Finance Report',
    //   url: '/organization-finance-report',
    //   icon: 'fa fa-list',
    // },
    // {
    //   name: 'Users-Report',
    //   url: '/users-report',
    //   icon: 'icon-user',
    // },
    // {
    //   name: 'CineAsia-Distributors',
    //   url: '/cineasia-distributors',
    //   icon: 'icon-list'
    // },
    // // {
    // //   name: 'CineAsia-Cinemas',
    // //   url: '/cineasia-cinemas',
    // //   icon: 'icon-list'
    // // }
  ],
};
if (dashboardName == "MyTiketi") {
  NavList.items.push({
    name: "Countries",
    url: "/masters/countries",
    icon: "fa fa-globe",
  });
}

export default NavList;

// export default {
//   items: [
//     // Saif
//     {
//       name: 'Ticketak Dashboard',
//       url: '/dashboard',
//       icon: 'icon-home',
//     },
//     // {
//     //   name: 'Splash Screen',
//     //   url: '/splashscreen',
//     //   icon: 'fa fa-file-photo-o',
//     // },
//     {
//       name: 'Banners',
//       url: '/banners',
//       icon: 'icon-diamond',
//       children: [
//         {
//           name: 'Banner List',
//           url: '/banners/bannerlist',
//           icon: 'fa fa-file-photo-o',
//         },
//         {
//           name: 'Add Movie Banner',
//           url: '/banners/addbanner',
//           icon: 'fa fa-file-photo-o',
//         },
//         {
//           name: 'Add Static Banner',
//           url: '/banners/addstaticbanner',
//           icon: 'fa fa-file-photo-o',
//         }
//       ]
//     },

//     // Saif
//     // {
//     //   name: 'App/Web Settings',
//     //   url: '/app-web-settings',
//     //   icon: 'icon-diamond',
//     //   children: [
//     //     {
//     //       name: 'Splash Screen',
//     //       url: '/app-web-settings/splashscreen',
//     //       icon: 'fa fa-file-photo-o',
//     //     },
//     //     {
//     //       name: 'Banner Image',
//     //       url: '/app-web-settings/bannerimage',
//     //       icon: 'fa fa-file-photo-o',
//     //     }
//     //   ]
//     // },

//     {
//       name: 'User Management',
//       url: '/user-management',
//       icon: 'fa fa-users',
//       children: [
//         {
//           name: 'Add User',
//           url: '/user-management/add-user',
//           icon: 'fa fa-user-plus',
//         },
//         {
//           name: 'Dashboard Users',
//           url: '/user-management/dash-users',
//           icon: 'fa fa-list',
//         },
//         {
//           name: 'Application Users',
//           url: '/user-management/app-users',
//           icon: 'fa fa-list',
//         },
//       ]
//     },
//     {
//       name: 'Cron Management',
//       url: '/cron-management',
//       icon: 'fa fa-users',
//       children: [
//         {
//           name: 'Manage Crons',
//           url: '/cron-management/cron-job',
//           icon: 'fa fa-user-plus',
//         },
//       ]
//     },
//     {
//       name: 'Exhibitor Management',
//       url: '/org-management',
//       icon: 'fa fa-building',
//       children: [
//         {
//           name: 'Organization',
//           url: '/org-management/organization',
//           icon: 'fa fa-building',
//           children: [
//             {
//               name: 'Add Organization',
//               url: '/org-management/organization/add-org',
//               icon: 'icon-speech',
//             },
//             {
//               name: 'List of Organizations',
//               url: '/org-management/organization/all-orgs',
//               icon: 'icon-speech',
//             }
//           ]
//         },
//         {
//           name: 'Cinema Site',
//           url: '/org-management/cinemas',
//           icon: 'icon-film',
//           children: [
//             {
//               name: 'Add Cinema',
//               url: '/org-management/cinemas/add-cinema',
//               icon: 'icon-speech',
//             },
//             {
//               name: 'Add Cinema Charges',
//               url: '/org-management/cinemas/add-cinema-charges',
//               icon: 'icon-speech',
//             },
//             {
//               name: 'List of Cinemas',
//               url: '/org-management/cinemas/all-cinemas',
//               icon: 'fa fa-list',
//             },
//           ]
//         },
//         {
//           name: 'Screens',
//           url: '/org-management/screens',
//           icon: 'fa fa-desktop',
//           children: [
//             {
//               name: 'Add Screen',
//               url: '/org-management/screens/add-screen',
//               icon: 'icon-speech',
//             },
//             {
//               name: 'Screen Seat Layout',
//               url: '/org-management/screens/add-screen-seatlayout',
//               icon: 'icon-speech'
//             },
//             {
//               name: 'List of Screens',
//               url: '/org-management/screens/all-screens',
//               icon: 'fa fa-list',
//             },
//           ]
//         },
//       ]
//     },
//     // {
//     //   name: 'Gogo Settings',
//     //   url: '/gogo-settings',
//     //   icon: 'fa fa-cog',
//     // },
//     {
//       name: 'Reports',
//       url: '/reports',
//       icon: 'fa fa-list',
//       children: [
//         {
//           name: 'Show Date Report',
//           url: '/reports/show-date',
//           icon: 'fa fa-list'
//         },
//         {
//           name: 'Transaction Date Report',
//           url: '/reports/transaction-date',
//           icon: 'fa fa-list'
//         }
//       ]
//     },
//     {
//       name: 'Movies',
//       url: '/movies',
//       icon: 'fa fa-ticket',
//       children: [
//         {
//           name: 'Add Movie',
//           url: '/movies/add-movie',
//           icon: 'icon-speech',
//         },
//         {
//           name: 'List of Movies',
//           url: '/movies/all-movies',
//           icon: 'fa fa-list',
//         },
//         {
//           name: 'Link Movies',
//           url: '/movies/link-movies',
//           icon: 'fa fa-list',
//         },
//         // {
//         //   name: 'Map Movies',
//         //   url: '/movies/map-movies',
//         //   icon: 'fa fa-list',
//         // },
//       ]
//     },
//     {
//       name: 'Platform Configuration',
//       url: '/masters',
//       icon: 'fa fa-sitemap',
//       children: [
//         {
//           name: 'Countries',
//           url: '/masters/countries',
//           icon: 'fa fa-globe',
//         },
//         {
//           name: 'States',
//           url: '/masters/states',
//           icon: 'fa fa-arrows-alt',
//         },
//         {
//           name: 'Cities',
//           url: '/masters/cities',
//           icon: 'fa fa-map-marker',
//         },
//         {
//           name: 'Areas',
//           url: '/masters/areas',
//           icon: 'fa fa-location-arrow',
//         },
//         {
//           name: 'Media',
//           url: '/masters/media',
//           icon: 'fa fa-file-video-o',
//         },
//         // {
//         //   name: 'Movies',
//         //   url: '/movies',
//         //   icon: 'fa fa-ticket',
//         //   children: [
//         //     {
//         //       name: 'Add Movie',
//         //       url: '/masters/movies/add-movie',
//         //       icon: 'icon-speech',
//         //     },
//         //     {
//         //       name: 'List of Movies',
//         //       url: '/masters/movies/all-movies',
//         //       icon: 'fa fa-list',
//         //     },
//         //     {
//         //       name: 'Map Movie',
//         //       url: '/masters/movies/map-movies',
//         //       icon: 'fa fa-list',
//         //     },
//         //   ]
//         // },
//         {
//           name: 'Language',
//           url: '/masters/language',
//           icon: 'fa fa-language',
//         },
//         {
//           name: 'Genre',
//           url: '/masters/genre',
//           icon: 'fa fa-users',
//         },
//         {
//           name: 'Info Type',
//           url: '/masters/info-type',
//           icon: 'fa fa-info',
//         },
//         {
//           name: 'Show Category',
//           url: '/masters/show-category',
//           icon: 'fa fa-file-movie-o',
//         },
//         // {
//         //   name: 'Template Type',
//         //   url: '/masters/add-template-type',
//         //   icon: 'icon-speech'
//         // },
//         {
//           name: 'Template',
//           url: '/masters/template',
//           icon: 'icon-speech'
//         },
//       ]
//     },
//     {
//       name: 'Notification',
//       url: '/notifications',
//       icon: 'fa fa-paper-plane-o',
//       children: [{
//         name: 'Send Notification',
//         url: '/notifications/send-notification',
//         icon: 'fa fa-comment-o'
//       }]
//     },
//     {
//       name: 'Third-Party',
//       url: '/third-party',
//       icon: 'fa fa-external-link',
//       children: [
//         {
//           name: 'Showbizz',
//           url: '/third-party/showbizz',
//           icon: 'fa fa-comment-o'
//         },
//         {
//           name: 'Vista',
//           url: '/third-party/vista',
//           icon: 'fa fa-comment-o'
//         },
//         {
//           name: 'Manual Sync',
//           url: '/third-party/manual-sync',
//           icon: 'fa fa-comment-o'
//         },
//       ]
//     },
//     {
//       name: 'Programming',
//       url: '/programming',
//       icon: 'icon-speech',
//       children: [
//         {
//           name: 'Create Programming',
//           url: '/programming/add-programming',
//           icon: 'icon-speech'
//         },
//         {
//           name: 'List of Programming',
//           url: '/programming/all-programming',
//           icon: 'icon-speech'
//         },
//         // {
//         //   name: 'Send Confirmation',
//         //   url: '/programming/send-confirmation',
//         //   icon: 'fa fa-paper-plane-o'
//         // },
//         // {
//         //   name: 'Custom Screening',
//         //   url: '/screenings',
//         //   icon: 'fa fa-file-movie-o',
//         //   children: [
//         //     {
//         //       name: 'Add Screening',
//         //       url: '/programming/screenings/add-screening',
//         //       icon: 'icon-speech',
//         //     },
//         //     {
//         //       name: 'List of Screenings',
//         //       url: '/programming/screenings/all-screenings',
//         //       icon: 'fa fa-list',
//         //     },
//         //   ]
//         // },
//       ]
//     },
//     {
//       name: 'Content',
//       url: '/content',
//       icon: 'fa fa-file-code-o',
//       children: [
//         {
//           name: 'Add Content',
//           url: '/content/add-content',
//           icon: 'icon-speech'
//         },
//         // {
//         //   name: 'Terms & Conditions1',
//         //   url: '/content/terms-and-conditions1',
//         //   icon: 'fa fa-book'
//         // },
//         // {
//         //   name: 'Terms & Conditions2',
//         //   url: '/content/terms-and-conditions2',
//         //   icon: 'fa fa-book'
//         // }
//       ]
//     },
//     {
//       name: 'Finance',
//       url: '/admin-finance',
//       icon: 'fa fa-list',
//       children: [
//         {
//           name: 'Finance Report',
//           url: '/admin-finance/report',
//           icon: 'fa fa-user-plus',
//         },
//         {
//           name: 'Add Transfer',
//           url: '/admin-finance/add-transfer',
//           icon: 'fa fa-list',
//         }
//       ]
//     },

//     {
//       name: 'Coupon Code',
//       url: '/coupon-code',
//       icon: 'fa fa-ticket'
//     },
//     // {
//     //   name: 'Admin Finance Report',
//     //   url: '/admin-finance-report',
//     //   icon: 'fa fa-list',
//     // },

//     {
//       name: 'Cinema Finance Report',
//       url: '/cin-finance-report',
//       icon: 'fa fa-list',
//     },
//     {
//       name: 'Organization Finance Report',
//       url: '/organization-finance-report',
//       icon: 'fa fa-list',
//     },
//     {
//       name: 'Users-Report',
//       url: '/users-report',
//       icon: 'icon-user',
//     },
//     {
//       name: 'CineAsia-Distributors',
//       url: '/cineasia-distributors',
//       icon: 'icon-list'
//     },
//     // {
//     //   name: 'CineAsia-Cinemas',
//     //   url: '/cineasia-cinemas',
//     //   icon: 'icon-list'
//     // }
//   ]
// }
