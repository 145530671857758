<template>
  <AppHeaderDropdown right no-caret>
    <div id="snackbar"></div>

    <template slot="header">
      <img src="img/user.svg" class="img-avatar" alt="admin@bootstrapmaster.com">
    </template>
    <template slot="dropdown">
      <!-- <b-dropdown-header tag="div" class="text-center"><strong>Account</strong></b-dropdown-header>
      <b-dropdown-item><i class="fa fa-bell-o" /> Updates
        <b-badge variant="info">{{ itemsCount }}</b-badge>
      </b-dropdown-item>
      <b-dropdown-item><i class="fa fa-envelope-o" /> Messages
        <b-badge variant="success">{{ itemsCount }}</b-badge>
      </b-dropdown-item>
      <b-dropdown-item><i class="fa fa-tasks" /> Tasks
        <b-badge variant="danger">{{ itemsCount }}</b-badge>
      </b-dropdown-item>
      <b-dropdown-item><i class="fa fa-comments" /> Comments
        <b-badge variant="warning">{{ itemsCount }}</b-badge>
      </b-dropdown-item>-->
      <b-dropdown-header tag="div" class="text-center">
        <strong>Settings</strong>
      </b-dropdown-header>
      <!-- <b-dropdown-item><i class="fa fa-user" /> Profile</b-dropdown-item>
      <b-dropdown-item><i class="fa fa-wrench" /> Settings</b-dropdown-item>
      <b-dropdown-item><i class="fa fa-usd" /> Payments
        <b-badge variant="secondary">{{ itemsCount }}</b-badge>
      </b-dropdown-item>
      <b-dropdown-item><i class="fa fa-file" /> Projects
        <b-badge variant="primary">{{ itemsCount }}</b-badge>
      </b-dropdown-item>
      <b-dropdown-divider />
      <b-dropdown-item><i class="fa fa-shield" /> Lock Account</b-dropdown-item>-->
      <b-dropdown-item @click="logout">
        <i class="fa fa-lock"/> Logout
      </b-dropdown-item>
    </template>
  </AppHeaderDropdown>
</template>

<script>
import { HeaderDropdown as AppHeaderDropdown } from "@coreui/vue";
import LoginService from "@/services/LoginService";

export default {
  name: "DefaultHeaderDropdownAccnt",
  components: {
    AppHeaderDropdown
  },
  data: () => {
    return { itemsCount: 42 };
  },
  methods: {
    logout() {
      LoginService.Logout()
        .then(response => {
          // const { data } = response;
          if (response && response.Status) {
            localStorage.clear();
            this.$cookie.delete("TICHTID");

            this.$cookie.delete("token");
            this.$cookie.delete("UPEM");
            // this.$cookie.delete('token_type');
            this.$router.push("/go-to/login");
          } else {
            var x = document.getElementById("snackbar");
            x.className = "show";
            x.innerHTML = response.Message;
            setTimeout(function() {
              x.className = x.className.replace("show", "");
            }, 3000);
          }
        })
        .catch(error => {});
    }
  }
};
</script>

<style>
.img-avatar {
  height: 30px;
  width: 20px;
  margin-top: 0px;
}
</style>
