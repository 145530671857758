<template>
  <div>
    <div v-for="(nav_links, index) in nav" :key="index">
      <div v-if="!nav_links.children" class="nav_link_parent">
        <b-link :to="nav_links.url">{{ nav_links.name }} </b-link>
      </div>

      <b-button
        block
        v-b-toggle="`accordion-${index}`"
        variant="info"
        class="parent"
        v-else
      >
        {{ nav_links.name }}
        <img src="@/assets/image/chevron-left.svg" class="acc_img" />
      </b-button>

      <div
        v-if="nav_links.children && !nav_links.children.children"
        class="nav_link_collapse"
      >
        <b-collapse
          :id="`accordion-${index}`"
          visible
          accordion="my-accordion"
          role="tabpanel"
          v-for="(sub_nav_links, sub_index) in nav_links.children"
          :key="sub_index"
          class="nav_link_collpase_parent"
          @click="nav_active == true ? 'active_nav' : ''"
        >
          <b-link
            class="nav_link_collpase_child"
            :to="sub_nav_links.url"
            v-if="!sub_nav_links.children"
            >{{ sub_nav_links.name }}</b-link
          >
          <div v-else-if="sub_nav_links.children">
            <b-button
              block
              v-b-toggle="`accordion-2${sub_index}`"
              class="parent_sub"
              v-if="sub_nav_links.children"
            >
              {{ sub_nav_links.name }}
              <img src="@/assets/image/chevron-left.svg" class="acc_img" />
            </b-button>
            <div
              v-for="(
                sub_links_child, sub_link_child_key
              ) in sub_nav_links.children"
              :key="sub_link_child_key"
              class=""
            >
              <b-collapse
                :id="`accordion-2${sub_index}`"
                accordion="sub-accordion"
                class="new_collapse_parent"
              >
                <b-link :to="sub_links_child.url">
                  {{ sub_links_child.name }}
                </b-link>
              </b-collapse>
            </div>
          </div>
        </b-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import nav from "@/_nav";

export default {
  name: "Sidebar",
  data() {
    return {
      nav: nav.items,
      nav_active: false,
      userRole: "",
      NewNav: [],
    };
  },
  beforeMount() {
    // alert(this.secureStore.get("TTURO"))
      // return false;
    this.getNavData();
    this.userRole = this.secureStore.get("TTURO")
      ? this.secureStore.get("TTURO")
      : "TICKETAK";
      if(this.userRole != "EventManager"){
      this.OrganizationID = this.secureStore.get("OrganizationID")
      ? this.secureStore.get("OrganizationID")
      : 0;
      }else{
        this.OrganizationID =0;
      }
      
      // alert(this.secureStore.get("TTHUN"))
    if (nav && this.userRole == "Cinema") {
      if (nav.items.length > 0) {
        nav.items.forEach((value) => {
          if (value.name == "View/Print Ticket" || value.name == "Transaction Log") {
            let obj = {
              icon: value.icon,
              name: value.name,
              url: value.url,
            };
            this.NewNav.push(obj);
          }
        });

        this.nav = [];
        this.nav = this.NewNav;
      }
    } else if (nav && this.userRole == "Organization") {
      if (nav.items.length > 0) {
        nav.items.forEach((value) => {
          if (value.name == "Accounts ReportsV2" && value.children.length > 0) {
            let childrenArr = [];
            if (value.children.length > 0) {
              value.children.forEach((children_value) => {
                if(this.OrganizationID == 7){
                  if (
                    children_value.name == "Mukta Accounting Report"
                  ) {
                    let children_obj = {
                      icon: children_value.icon,
                      name: children_value.name,
                      url: children_value.url,
                    };
                    childrenArr.push(children_obj);
                  }
                }else if(this.OrganizationID == 8){
                  if (
                    children_value.name == "Novo Accounting Report"
                  ) {
                    let children_obj = {
                      icon: children_value.icon,
                      name: children_value.name,
                      url: children_value.url,
                    };
                    childrenArr.push(children_obj);
                  }
                }else{
                  if (
                    children_value.name == "Cineco Detail Report" ||
                    children_value.name == "Cineco Accounts Report"
                  ) {
                    let children_obj = {
                      icon: children_value.icon,
                      name: children_value.name,
                      url: children_value.url,
                    };
                    childrenArr.push(children_obj);
                  }
                }

              });
            }
            let obj = {
              children: childrenArr,
              icon: value.icon,
              name: value.name,
              url: value.url,
            };
            this.NewNav.push(obj);
          }
        });

        this.nav = [];
        this.nav = this.NewNav;
      }
    } else if (nav && this.userRole == "EventManager") {
      console.log(nav);
      if (nav.items.length > 0) {
        nav.items.forEach((value) => {
          if (value.name == "Event Info" && value.children.length > 0) {
            let childrenArr = [];
            if (value.children.length > 0) {
              value.children.forEach((children_value) => {
                if (
                  children_value.name == "Events" ||
                  children_value.name == "View Events" 
                ) {
                  let children_obj = {
                    icon: children_value.icon,
                    name: children_value.name,
                    url: children_value.url,
                  };
                  childrenArr.push(children_obj);
                }
              });
            }
            let obj = {
              children: childrenArr,
              icon: value.icon,
              name: value.name,
              url: value.url,
            };
            this.NewNav.push(obj);
          }
          if (value.name == "Events Report" && value.children.length > 0) {
            let childrenArr = [];
            if (value.children.length > 0) {
              value.children.forEach((children_value) => {
                if (
                  children_value.name == "Reports"  
                ) {
                  let children_obj = {
                    icon: children_value.icon,
                    name: children_value.name,
                    url: children_value.url,
                  };
                  childrenArr.push(children_obj);
                }
              });
            }
            let obj = {
              children: childrenArr,
              icon: value.icon,
              name: value.name,
              url: value.url,
            };
            this.NewNav.push(obj);
          }
        });

        this.nav = [];
        this.nav = this.NewNav;
      }
    }else if(nav && this.userRole == "Finance" && this.secureStore.get("TTHUN")=="intlaccounts"){
      if (nav.items.length > 0) {
        nav.items.forEach((value) => {
          if (value.name == "Accounts ReportsV2" && value.children.length > 0) {
            let childrenArr = [];
            if (value.children.length > 0) {
              value.children.forEach((children_value) => {
                if(this.OrganizationID == 7){
                  if (
                    children_value.name == "Mukta Accounting Report"
                  ) {
                    let children_obj = {
                      icon: children_value.icon,
                      name: children_value.name,
                      url: children_value.url,
                    };
                    childrenArr.push(children_obj);
                  }
                }else if(this.OrganizationID == 8){
                  if (
                    children_value.name == "Novo Accounting Report"
                  ) {
                    let children_obj = {
                      icon: children_value.icon,
                      name: children_value.name,
                      url: children_value.url,
                    };
                    childrenArr.push(children_obj);
                  }
                }else{
                  if (
                    children_value.name == "Cineco Detail Report" ||
                    children_value.name == "Cineco Accounts Report" ||
                    children_value.name == "Detail Accounts Report" ||
                    children_value.name == "Booking Details Report" ||
                    children_value.name == "Internal Accounts Report" ||
                    children_value.name == "Novo Accounting Report" ||
                    children_value.name == "Mukta Accounting Report"
                  ) {
                    let children_obj = {
                      icon: children_value.icon,
                      name: children_value.name,
                      url: children_value.url,
                    };
                    childrenArr.push(children_obj);
                  }
                }

              });
            }
            let obj = {
              children: childrenArr,
              icon: value.icon,
              name: value.name,
              url: value.url,
            };
            this.NewNav.push(obj);
          }
        });

        this.nav = [];
        this.nav = this.NewNav;
      }
    }
     
  },
  methods: {
    getNavData() {
      // console.log(this.nav);
    },
  },
};
</script>
<style>
.nav_link_parent {
  background-color: #fff;
  border-top: none !important;
  border-bottom: 1px solid #cd1719;
  border-right: 1px solid #cd1719;
  padding: 8px 15px;
}
.nav_link_collapse {
  margin: 0px;
}
.nav_link_collapse a:hover {
  color: #282e34;
  text-decoration: none;
}
.nav_link_collpase_parent {
  padding: 5px 5px 5px 20px;
  background-color: white;
  border-bottom: 1px solid #d0d0d0;
  border-right: 1px solid #d0d0d0;
}
.nav_link_collpase_parent:active {
  background-color: #a9a9a9;
}
.nav_link_collpase_parent:focus {
  background-color: #a9a9a9;
}
.nav_link_collpase_parent:hover {
  /*background-color: 		#A9A9A9;*/
}
.nav_link_collpase_child {
  color: #000;
  padding-left: 10px;
}
.nav_link_collpase_child:active {
  color: green;
}
.active_nav {
  background-color: black !important;
}
.new_collapse_parent {
  padding: 5px !important;
  margin-left: 20px;
}
</style>
